export default [
  {
    key: 'meronPayoutRatio',
    label: 'field.meronPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'meron',
    cols: 12,
  },
]
