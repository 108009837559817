<template>
  <b-modal
    v-model="showModal"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.save')"
    :cancel-title="$t('button.close')"
    centered
    size="sm"
    @ok="save"
  >
    <validation-observer ref="popupForm">
      <n-input
        v-if="type == 1"
        :fields="meronFields"
        v-model="data"
        class="data-form"
      ></n-input>
      <n-input
        v-else-if="type == 2"
        :fields="walaFields"
        v-model="data"
        class="data-form"
      ></n-input>
      <n-input
        v-else-if="type == 3"
        :fields="drawFields"
        v-model="data"
        class="data-form"
      ></n-input>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import NInput from "@/components/NInput";
import meronFields from "./meronForm";
import walaFields from "./walaForm";
import drawFields from "./drawForm";

export default {
  components: {
    BModal,
    NInput,
  },
  data() {
    return {
      showModal: false,
      meronFields,
      walaFields,
      drawFields,
      type: 0,
      data: {
        walaPayoutRatio: null,
        meronPayoutRatio: null,
        drawPayoutRatio: null,
      },
    };
  },
  methods: {
    show(type) {
      this.data = {
        walaPayoutRatio: null,
        meronPayoutRatio: null,
        drawPayoutRatio: null,
      };
      this.type = type;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    save(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.popupForm.validate().then((success) => {
        if (success) {
          this.$emit("save", this.type, this.data);
          this.hide()
        }
      });
    },
  },
};
</script>
