<template>
  <div>
    <b-card header-tag="header">
      <b-row>
        <b-col md="12">
          <validation-observer ref="parleyDefaultForm" #default="{ invalid }">
            <n-form-confirmation
              key="parleyDefaultForm"
              class="data-form"
              @submit="submit"
              :form="$refs.parleyDefaultForm"
              :disabled="invalid"
            >
              <b-row>
                <b-col md="4" class="mb-2">
                  <b-button
                    block
                    :disabled="loading"
                    class="fightResult-1"
                    @click="$refs.setPayoutModal.show(1)"
                  >
                    {{ $t("field.meron") }}
                  </b-button>
                </b-col>
                <b-col md="4" class="mb-2">
                  <b-button
                    block
                    :disabled="loading"
                    class="fightResult-3"
                    @click="$refs.setPayoutModal.show(3)"
                  >
                    {{ $t("field.draw") }}
                  </b-button>
                </b-col>
                <b-col md="4" class="mb-2">
                  <b-button
                    block
                    :disabled="loading"
                    class="fightResult-2"
                    @click="$refs.setPayoutModal.show(2)"
                  >
                    {{ $t("field.wala") }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-for="(match, midx) in matches"
                :key="`match-${midx}`"
                :class="{
                  'border-top': midx !== 0,
                  'py-1': midx !== 0,
                  'pb-1': midx === 0,
                }"
              >
                <b-col md="12">
                  <div class="d-flex">
                    <h4 class="bold">#{{ match.fightMatchNo }}</h4>
                  </div>
                </b-col>
                <b-col md="4">
                  <b-form-group class="meron">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`meronPayoutRatio`"
                      :name="$t('field.meronPayoutRatio')"
                      rules="required|decimal:2|min_value:0"
                    >
                      <label>
                        {{ $t("field.meronPayoutRatio") }}
                      </label>
                      <b-form-input
                        v-model="match.meronPayout"
                        :placeholder="$t('field.meronPayoutRatio')"
                        :name="`meronPayoutRatio`"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group class="draw">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`drawPayoutRatio`"
                      :name="$t('field.drawPayoutRatio')"
                      rules="required|decimal:2|min_value:0"
                    >
                      <label>
                        {{ $t("field.drawPayoutRatio") }}
                      </label>
                      <b-form-input
                        v-model="match.drawPayout"
                        :placeholder="$t('field.drawPayoutRatio')"
                        :name="`drawPayoutRatio`"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group class="wala">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`walaPayoutRatio`"
                      :name="$t('field.walaPayoutRatio')"
                      rules="required|decimal:2|min_value:0"
                    >
                      <label>
                        {{ $t("field.walaPayoutRatio") }}
                      </label>
                      <b-form-input
                        v-model="match.walaPayout"
                        :placeholder="$t('field.walaPayoutRatio')"
                        :name="`walaPayoutRatio`"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group class="m-0">
                    <b-form-checkbox
                      :id="`isCanBet-${midx}`"
                      v-model="match.isCanBet"
                      :name="`isCanBet-${midx}`"
                      :value="true"
                      :unchecked-value="false"
                    >
                      {{ $t("status.canBet") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="floating-submit-button"
                v-if="$can('update', 'parley-setup')"
              >
                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="live.isSingleBet ? `danger` : `success`"
                    class="mt-1"
                    block
                    :loading="loading"
                    :disabled="!live.id"
                    @submit="changeSingleBetStatus"
                  >
                    {{
                      live.isSingleBet
                        ? $t("button.stopSingleBet")
                        : $t("button.startSingleBet")
                    }}
                  </n-button-loading>
                </b-col>
                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="live.isParleyBet ? `danger` : `success`"
                    class="mt-1"
                    block
                    :loading="loading"
                    :disabled="!live.id"
                    @submit="changeParleyStatus"
                  >
                    {{
                      live.isParleyBet
                        ? $t("button.stopParley")
                        : $t("button.startParley")
                    }}
                  </n-button-loading>
                </b-col>
                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="submit"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-1"
                    block
                    :loading="loading"
                    :disabled="invalid"
                  >
                    {{ $t("button.saveChanges") }}
                  </n-button-loading>
                </b-col>
              </b-row>
            </n-form-confirmation>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>

    <set-payout-modal ref="setPayoutModal" @save="massUpdatePayout" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BEmbed,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BButton,
} from "bootstrap-vue";
import NFormConfirmation from "@/components/NFormConfirmation";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import SetPayoutModal from "../setPayoutModal";

const FightSetupRepository = Repository.get("fightSetup");
const ParleySetupRespository = Repository.get("parleySetup");
// const SingleBetRespository = Repository.get("singleBetRespository");
const channelId = 2;
const ccy = 116;

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BEmbed,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    NFormConfirmation,
    NButtonLoading,
    SetPayoutModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      live: {},
      matches: [],
      loading: false,
      channel: 1,
    };
  },
  created() {
    this.showLive();
    this.show();
  },
  methods: {
    massUpdatePayout(type, data) {
      if (type == 1) {
        const matches = this.matches.map((element) => {
          return {
            ...element,
            meronPayout: data.meronPayoutRatio,
          };
        });
        this.matches = [...matches];
      } else if (type == 2) {
        const matches = this.matches.map((element) => {
          return {
            ...element,
            walaPayout: data.walaPayoutRatio,
          };
        });
        this.matches = [...matches];
      } else if (type == 3) {
        const matches = this.matches.map((element) => {
          return {
            ...element,
            drawPayout: data.drawPayoutRatio,
          };
        });
        this.matches = [...matches];
      }
    },
    showLive() {
      const data = {
        channelId: channelId,
      };
      FightSetupRepository.current(data).then((response) => {
        const data = response.data.data;
        if (data) {
          const index = data.setting.findIndex((item) => {
            return item.ccy === ccy;
          });

          this.live = {
            ...data,
          };
        }
      });
    },
    changeSingleBetStatus() {
      if (!this.live.id) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(
          this.live.isSingleBet
            ? this.$t("alert.youAreGoingToStopSingleBet")
            : this.$t("alert.youAreGoingToStartSigleBet"),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: this.live.isSingleBet ? "danger" : "success",
            okTitle: this.live.isSingleBet
              ? this.$t("button.stopSingleBet")
              : this.$t("button.startSingleBet"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.loading = true;
            const data = {
              id: this.live.id,
              channelId: channelId,
              isSingleBet: !this.live.isSingleBet,
              isParleyBet: this.live.isParleyBet,
            };

            ParleySetupRespository.updateParleyStatus(data)
              .then((response) => {
                this.live.isSingleBet = !this.live.isSingleBet;
              })
              .catch()
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
    changeParleyStatus() {
      if (!this.live.id) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(
          this.live.isParleyBet
            ? this.$t("alert.youAreGoingToStopParley")
            : this.$t("alert.youAreGoingToStartParley"),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: this.live.isParleyBet ? "danger" : "success",
            okTitle: this.live.isParleyBet
              ? this.$t("button.stopParley")
              : this.$t("button.startParley"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.loading = true;
            const data = {
              id: this.live.id,
              channelId: channelId,
              isParleyBet: !this.live.isParleyBet,
              isSingleBet: this.live.isSingleBet,
            };

            ParleySetupRespository.updateParleyStatus(data)
              .then((response) => {
                this.live.isParleyBet = !this.live.isParleyBet;
              })
              .catch()
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
    show() {
      const params = {
        channelId: channelId,
      };
      ParleySetupRespository.listParley(params).then((response) => {
        const data = response.data.data;
        this.matches = [...data];
      });
    },
    submit() {
      this.$refs.parleyDefaultForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            data: this.matches,
          };
          ParleySetupRespository.saveParley(data)
            .then((response) => {
              const data = response.data.data;
            })
            .catch((error) => {
              const message = error.response?.data?.message;
              if (message) {
                this.$swal({
                  title: "Error!",
                  text: JSON.stringify(message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
