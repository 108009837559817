export default [
  {
    key: 'drawPayoutRatio',
    label: 'field.drawPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'draw',
    cols: 12,
  },
]
