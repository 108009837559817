<template>
  <b-tabs pills v-model="tabIndex">
    <b-tab lazy v-if="$can('read', 'parley-setup')">
      <template #title>
        <feather-icon icon="TvIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("breadcrumb.setup") }}</span>
      </template>

      <parley-setup />
    </b-tab>
    <b-tab lazy v-if="$can('read', 'parley-data-form')">
      <template #title>
        <feather-icon icon="DatabaseIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("breadcrumb.dataForm") }}</span>
      </template>

      <parley-default-setup />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import tabRoute from "./tabRoute";
import parleySetup from "./parleySetup/parleySetup.vue";
import parleyDefaultSetup from "./parleyDefaultSetup/parleyDefaultSetup.vue";

export default {
  components: {
    BTabs,
    BTab,
    parleySetup,
    parleyDefaultSetup,
  },

  computed: {
    tabIndex: {
      get() {
        let routeName = this.$route.name;

        let index = this.route.findIndex((item) => {
          return item.route === routeName;
        });

        return index !== -1 ? index : 0;
      },
      set(value) {
        const route = this.route[value]?.route;
        if (route) {
          this.$router.replace({
            name: route,
          });
        }
      },
    },
  },
  methods: {},
  setup() {
    const route = [...tabRoute];

    return { route };
  },
};
</script>

<style>
</style>